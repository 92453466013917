<template>
  <v-container style="margin-top: 90px">
    <v-snackbar v-model="snackbar">
      <v-icon class="primary--text pa-1">mdi-alert</v-icon>
      <span class="white--text">{{ snack }}</span>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" color="pink" text @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <div>
      <v-row align="center" justify="center">
        <v-col style="margin-top: -100px" cols="12" sm="8" md="8">
          <v-card class="opa elevation-12 pa-3" v-if="!loadingVinivox && !loadingConessence">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title><v-icon class="mr-2">mdi-lock</v-icon>Connexion à Cartovia</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon large target="_blank" v-on="on">
                    <v-icon>mdi-login</v-icon>
                  </v-btn>
                </template>
                <span class="white--text">Se connecter</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <v-form v-model="valid" ref="form" :lazy-validation="false">
                <v-text-field v-model="email" label="E-mail" name="Email" :rules="emailRules" prepend-icon="mdi-email"
                  type="email"></v-text-field>
                <v-text-field id="password" :rules="nameRules" v-model="password" label="Votre mot de passe"
                  @click:append="togglePassword" :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  prepend-icon="mdi-lock" placeholder="******" :type="showPassword ? 'text' : 'password'" name="password"
                  @keyup.enter="login"></v-text-field>
              </v-form>
            </v-card-text>

            <v-row class="justify-center pt-4">
              <v-col cols="12" lg="4" sm="6" md="6" class="d-flex justify-center">
                <v-btn outlined color="primary" @click="$router.push('/register')">
                  <v-icon>mdi-account-plus</v-icon> Pas encore inscrit ?
                </v-btn>
              </v-col>
              <v-col cols="12" lg="4" sm="6" md="6" class="d-flex justify-center">
                <v-btn outlined color="primary" @click="$router.push('/reset')">
                  <v-icon>mdi-lock-reset</v-icon> Mot de passe perdu ?
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center pt-4 mb-6">
              <v-btn type="submit" :disabled="!valid" :loading="loading" @click="login" color="primary">
                <v-icon>mdi-lock</v-icon>Se connecter
              </v-btn>
            </v-row>

            <!-- <div class="horizontal-bar mt-4 mb-4">
              <span>Ou connectez-vous</span>
            </div> -->


            <!-- <v-row class="justify-center pt-4">
              <v-col cols="12" lg="3" sm="6" md="6" class="d-flex justify-center">
                <a @click="OauthApple">
                  <v-btn color="black" dark rounded><v-icon>mdi-apple</v-icon> Apple Connect</v-btn>
                </a>
              </v-col>
              <v-col cols="12" lg="3" sm="6" md="6" class="d-flex justify-center">
                <a @click="Oauth">
                  <v-btn color="info" dark rounded><v-icon>mdi-google</v-icon> Google Connect</v-btn>
                </a>
              </v-col>
            </v-row>

            <div class="horizontal-bar mt-8 mb-4">
              <span>Ou encore avec nos apps 🪄</span>
            </div>

            <v-row class="justify-center pt-4">
              <v-col cols="12" lg="3" sm="6" md="6" class="d-flex justify-center">
                <a @click="OauthVinivox">
                  <v-btn small color="#9A1F3C" dark rounded><v-icon>mdi-fruit-grapes</v-icon> Vinivox Connect</v-btn>
                </a>
              </v-col>
              <v-col cols="12" lg="3" sm="6" md="6" class="d-flex justify-center">
                <a @click="OauthConessence">
                  <v-btn class="ml-2" small color="#018253" dark rounded><v-icon>mdi-forest</v-icon> Conessence
                    Connect</v-btn>
                </a>
              </v-col>
            </v-row> -->

          </v-card>
          <v-card class="opa elevation-12" v-else-if="loadingVinivox == true">
            <v-toolbar color="#9A1F3C" dark flat>
              <v-btn @click="loadingVinivox = false; loadingConessence = false" icon>
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-toolbar-title><v-icon class="mr-2">mdi-fruit-grapes</v-icon>Connexion avec Vinivox</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="loadingVinivox = false; loadingConessence = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <img class="d-flex justify-center mx-auto" width="300" src="@/assets/vinivox.png" />
              <v-form v-model="valid" ref="form" :lazy-validation="false">
                <v-text-field v-model="email" label="Votre e-mail Vinivox" name="Email" :rules="emailRules"
                  prepend-icon="mdi-email" type="email"></v-text-field>
                <v-text-field id="password" :rules="nameRules" v-model="password" label="Votre mot de passe Vinivox"
                  @click:append="togglePassword" :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  prepend-icon="mdi-lock" placeholder="******" :type="showPassword ? 'text' : 'password'" name="password"
                  @keyup.enter="login"></v-text-field>
              </v-form>
            </v-card-text>

            <v-row class="d-flex justify-center pt-4 mb-6 ma-4 pb-4">
              <v-btn type="submit" @click="login" color="#9A1F3C" dark>
                <v-icon>mdi-check</v-icon>Se connecter avec Vinivox
              </v-btn>
            </v-row>

          </v-card>
          <v-card class="opa elevation-12" v-else-if="loadingConessence == true">
            <v-toolbar color="#018253" dark flat>
              <v-btn @click="loadingVinivox = false; loadingConessence = false" icon>
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-toolbar-title><v-icon class="mr-2">mdi-forest</v-icon>Avec Conessence</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="loadingVinivox = false; loadingConessence = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <img class="d-flex justify-center mx-auto" width="300" src="@/assets/conessence_connect.png" />

              <v-form v-model="valid" ref="form" :lazy-validation="false">
                <v-text-field v-model="email" label="Votre e-mail Conessence" name="Email" :rules="emailRules"
                  prepend-icon="mdi-email" type="email"></v-text-field>
                <v-text-field id="password" :rules="nameRules" v-model="password" label="Votre mot de passe Conessence"
                  @click:append="togglePassword" :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  prepend-icon="mdi-lock" placeholder="******" :type="showPassword ? 'text' : 'password'" name="password"
                  @keyup.enter="login"></v-text-field>
              </v-form>
            </v-card-text>

            <v-row class="d-flex justify-center pt-4 mb-6 ma-4 pb-4">
              <v-btn type="submit" @click="login" color="#018253" dark>
                <v-icon>mdi-check</v-icon>Se connecter avec Conessence
              </v-btn>
            </v-row>

          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>


<script>
import xhr from "../plugins/axios";
import { initializeApp } from 'firebase/app';
import 'firebase/compat/auth';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyAI1ZOHrb1zKj-f1Iu6r6BTG87t5qbfUeE",
  authDomain: "cartovia-3a989.firebaseapp.com",
  projectId: "cartovia-3a989",
  storageBucket: "cartovia-3a989.appspot.com",
  messagingSenderId: "1080134626602",
  appId: "1:1080134626602:web:13828952dd2badd70b4253"
};

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { OAuthProvider } from "firebase/auth";

const firebase = initializeApp(firebaseConfig);

export default {
  name: "Login",
  data() {
    return {
      showPassword: false,
      loadingVinivox: false,
      loadingConessence: false,
      snackbar: false,
      loading: false,
      valid: true,
      snack: "Mauvais e-mail ou mot de passe. Veuillez réessayer.",
      email: "",
      password: "",
      nameRules: [
        (v) => !!v || "Mot de passe obligatoire",
        (v) =>
          (v && v.length >= 8) ||
          "Mot de passe doit faire au moins 8 caractères",
      ],
      emailRules: [
        (v) => !!v || "E-mail obligatoire",
        (v) => /.+@.+\..+/.test(v) || "E-mail doit être valide",
      ],
    };
  },
  created() {
  },
  async mounted() {
    if (this.$route.query.confirmed === "true") {
      this.snack = "Bienvenue sur Cartovia ✨"
      this.snackbar = true
      let email = this.$route.query.email
      let token = this.$route.query.token
      let id = this.$route.query.id

      let res = await xhr.post("/autologin-confirmed", { email, token, id })

      if (!res.data || res.data === null || res.data.success === false) {
        this.snackbar = true;
        this.loading = false;
      } else if (res.data.success === true) {
        this.loading = false;
        localStorage.setItem("uid", res.data.user.uid);
        localStorage.setItem("email", res.data.user.email);
        localStorage.setItem("user", JSON.stringify(res.data.user));

        window.location = `/register-premium?confirmed=true`;
      }

      this.loading = false;

      localStorage.setItem("uid", uid);
      localStorage.setItem("email", res.data.user.email);
      localStorage.setItem("user", JSON.stringify(res.data.user));


      window.location = `/inventaire/${this.id}`;

    }
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    liAuth() {
      IN.User.authorize(() => {
        this.back(data);
      });
    },
    back(data) {
      IN.API.Profile("me").fields(["id", "email-address"])
        .result((result) => {
          this.setLoginBadge(result.values[0]);
        })
    },
    setLoginBadge(profile) {
      console.log("profile", profile);
    },
    OauthApple() {
      const auth = getAuth();
      const provider = new OAuthProvider('apple.com');

      provider.addScope('email');
      provider.addScope('name');
      provider.setCustomParameters({
        locale: 'fr'
      });

      signInWithPopup(auth, provider)
        .then(async (result) => {
          // The signed-in user info.
          let user = result.user;

          // Apple credential
          // const credential = OAuthProvider.credentialFromResult(result);
          // const accessToken = credential.accessToken;
          // const idToken = credential.idToken;


          let res = await xhr.post("/exist-oauth", { email: user.email, oauth: "apple" })
          if (!res.data || res.data === null || res.data.success === false) {
            this.snackbar = true
            this.snack = "Aucun compte Apple est associé à l'application Cartovia"
            this.loading = false;
          } else if (res.data) {

            localStorage.setItem("id", user.uid);
            localStorage.setItem("email", user.email);
            localStorage.setItem("name", user.displayName);
            localStorage.setItem("user", JSON.stringify(user));

            window.location = "/";
          }

          // IdP data available using getAdditionalUserInfo(result)
          // ...
        })
        .catch((error) => {

          this.snackbar = true
          this.snack = "Aucun compte Apple est associé à l'application Cartovia"


        });

    },
    OauthLn() {
      const displayProfileData = (data) => {
      }
      const onError = (error) => {
        console.log(error);
      }
      const getProfileData = () => {
        IN.API.Profile("me").fields("id", "first-name", "last-name", "headline", "location", "picture-url", "public-profile-url", "email-address").result(displayProfileData).error(onError);
      }

      IN.Event.on(IN, "auth", getProfileData);
      console.log("ici");
    },
    Oauth() {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();

      signInWithPopup(auth, provider)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;

          let res = await xhr.post("/exist-oauth", { email: user.email, oauth: "google" })
          if (!res.data || res.data === null || res.data.success === false) {
            this.snackbar = true
            this.snack = "Aucun compte Apple est associé à l'application Cartovia"
            this.loading = false;
          } else if (res.data) {
            localStorage.setItem("id", user.uid);
            localStorage.setItem("email", user.email);
            localStorage.setItem("url", user.photoURL);
            localStorage.setItem("name", user.displayName);
            localStorage.setItem("user", JSON.stringify(user));

            window.location = "/";

          }

          // IdP data available using getAdditionalUserInfo(result)
          // ...
        }).catch((error) => {
          this.snackbar = true
          this.snack = "Aucun compte Google est associé à l'application Google"
        });
    },
    OauthVinivox() {
      this.loadingVinivox = true;
    },
    OauthConessence() {
      this.loadingConessence = true;
    },
    login() {
      this.loading = true;
      let path = "/login"
      let provider = ""
      if (this.loadingVinivox == true || this.loadingConessence) {
        path = "/login-other"
        provider = this.loadingConessence == true ? "conessence" : "vinivox"
      }
      xhr
        .post(path, { email: this.email, password: this.password, provider })
        .then((res) => {
          if (!res.data || res.data === null || res.data.success === false) {
            this.snackbar = true;
            this.loading = false;
          } else if (res.data.success === true) {
            this.loading = false;
            if (this.loadingVinivox == true || this.loadingConessence == true) {
              localStorage.setItem("uid", res.data.message.user.id);
            } else {
              localStorage.setItem("uid", res.data.message._id);
            }
            localStorage.setItem("email", res.data.message.user.email);
            localStorage.setItem("user", JSON.stringify(res.data.message));

            let user = {}

            window.location = "/?confirmed=true";
          } else {
            this.snackbar = true
            this.snack = "Aucun compte est associé à l'application Cartovia"
            this.loading = false;
          }
        });
    },
  },
};
</script>

<style>
.separator {
  border: 1px solid #000;
  /* Vous pouvez personnaliser le style de la séparation */
}

.text-center {
  text-align: center;
  margin-top: 10px;
  /* Ajustez la marge selon vos besoins */
}

.horizontal-bar {
  position: relative;
  border-top: 1px solid #ccc;
  /* Couleur grise pour le trait */
  text-align: center;
  margin: 20px 0;
  /* Ajustez la marge selon vos besoins */
}

.horizontal-bar span {
  background-color: #fff;
  /* Fond blanc pour le texte */
  padding: 0 10px;
  /* Espace autour du texte */
  position: relative;
  top: -11px;
  /* Ajustez la position verticale du texte */
}
</style>